<template>
  <div class="tax-contact">
    <div class="tax-contact__header bold">
      {{ $t('desc_tax_contact_header') }}
    </div>
    <div class="tax-contact__line">
      <div class="tax-contact__line__header tax-contact__li">
        {{ $t('desc_tax_contact_line_header') }}
      </div>
      <div class="tax-contact__line__content tax-contact__content-wrapper">
        <div class="tax-contact__line__content__row">
          <div class="tax-contact__line__content__column tax-contact__line__content__column--end">
            <a
              class="tax-contact__line__content__btn"
              href="javascript:void(0)"
              @click="openLinkInBrowser(appInfo.line_contact)"
            >
              <img
                src="/images/icons/icon-line-app.svg"
                alt="line"
              >
              {{ $t('btn_add_friend') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="tax-contact__phone">
      <div class="tax-contact__phone__header tax-contact__li">
        {{ $t('desc_tax_contact_phone_header') }}
      </div>
      <div class="tax-contact__phone__content tax-contact__content-wrapper">
        <a href="tel:0120818126" class="bold">
          0120-818-126
        </a>
        <div class="tax-contact__content-wrapper">
          {{ $t('desc_tax_contact_phone_time_range') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {triggerMobileAction} from '@/utils/webview.util'
import { mapGetters } from 'vuex'
import {OPEN_BROWSER, OPEN_WEBVIEW} from '@/enums/mobile-event.enum'

export default {
  name: 'TaxContactBlock',
  computed: {
    ...mapGetters({
      hasPlatform: 'config/hasPlatform',
      appInfo: 'config/appInfo',
    }),
  },
  created () {
    this.$store.dispatch('config/getAppGeneralInfo')
  },
  methods: {
    openLinkInBrowser (url) {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_BROWSER, {
          url
        })
      } else {
        window.location.href = url
      }
    },
    openLinkInWebview (url) {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_WEBVIEW, {
          url
        })
      } else {
        window.location.href = url
      }
    },
  }
}
</script>

<style scoped lang="scss">
.tax-contact {
  color: #555555;
  font-size: 10px;
  line-height: 1.5;

  &__header {
    border-left: 5px solid #DDBB00;
    padding-left: 5px;
    color: #000000;
    margin-bottom: 16px;
    font-size: 14px;
  }

  &__content-wrapper {
    padding-left: 16px;

    & & {
      padding-left: 2px;
    }
  }

  &__note {
    color: #EA3323;
    margin-bottom: 15px;
  }

  &__line {
    margin-bottom: 15px;

    &__content {
      &__row {
        display: flex;
        width: 100%;
      }

      &__column--end {
        display: flex;
        align-items: end;
        margin-left: -5px;
      }

      &__btn {
        height: 23px;
        width: 70px;
        display: flex;
        padding: 4px 0;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        background-color: #00b900;
        color: white;
        font-size: 8px;
        text-decoration: none;

        img {
          height: 100%;
          margin-right: 5px;
        }
      }
    }
  }

  &__phone {
    a {
      text-decoration: none;
    }
  }

  &__li {
    &:before {
      content: '■';
      font-size: 7px;
    }
  }
}
</style>
